.navbar {
  background: #fff;
  padding: 16px 12px;
  border-radius: 10px;
  width: 85px;
  border: 1px solid #d3dfeb;
  display: grid;

  column-gap: 2px;
  transition: 0.15s;
  &.open {
    width: 222px;
    .navbarItem {
      justify-content: start;
    }
  }
  &Item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    padding: 8px 11px;
    border-radius: 6px;
    color: rgb(108, 134, 159);
    cursor: pointer;
    overflow: hidden;
    svg {
      width: 18px;
      min-width: 18px;
      height: 18px;
      fill: rgb(108, 134, 159);
      stroke: rgb(108, 134, 159);
      &.collaps {
        transform: rotate(180deg);
      }
    }
    &.active {
      svg {
        fill: #000000;
        stroke: #000000;
      }
      color: #000000;
    }
    span {
      margin-left: 12px;
      white-space: nowrap;
      line-height: 18px;
    }
    &:hover {
      background-color: rgb(241, 245, 248);
    }
  }
}

.version {
  margin-top: 25px;
  margin-left: 11px;
  font-size: 13px;
  opacity: 0.6;
}
